import { graphql, Link, StaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { Box, Text } from "../../components/Core"
import { device } from "../../utils"
import { OverlineArrowButton } from "../Buttons/OverlineArrowButton"

const query = graphql`
  query {
    BackgroundMan: file(relativePath: { eq: "referral-background-man.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 50 }
        )
      }
    }
    LeftCircle: file(relativePath: { eq: "referral-banner-left-circle.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 50 }
        )
      }
    }
  }
`

const StyledTermsBox = styled(Box)`
  background: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  padding: 8px;
  align-items: flex-end;
  @media (max-width: 576px) {
    margin-top: 1rem;
  }
`

const HoverStyled = styled(Text)`
  &:hover {
    color: ${({ theme }) => theme.colors.textHover};
    transition-timing-function: ease-in-out !important;
    -webkit-transition-timing-function: ease-in-out !important;
    transition-duration: 150ms;
  }
`

export const ReferralBanners = () => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const StyledContainer = styled(Container)`
          padding: 0;
          padding-top: 4.5rem;
          position: relative;
          margin-bottom: 5rem;
          @media ${device.md} {
            &:after {
              content: "";
              top: 0;
              right: 1rem;
              border: 0;
              position: absolute;
              width: 50%;
              height: 100%;
              background-image: url(${getImage(data.BackgroundMan).images
                .fallback.src});
              background-repeat: no-repeat;
              background-position-x: right;
              background-position-y: top;
              background-size: contain;
              -webkit-filter: drop-shadow(6px 1px 3px);
              filter: drop-shadow(6px 1px 3px);
            }
          }
          &:before {
            content: "";
            top: 0;
            left: -3.875rem;
            border: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url(${getImage(data.LeftCircle).images.fallback
              .src});
            background-repeat: no-repeat;
            background-position-x: left;
            background-position-y: 0.625rem;
            background-size: contain;
            background-size: 7.75rem;
            z-index: 1;
          }
        `

        const StyledBox = styled(Box)`
          min-height: 240px;
          background-color: #0080b2;
          border-radius: 8px;
          position: relative;
          padding: 2.5rem;
          &:before {
            content: "";
            top: 0;
            left: 0;
            border: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url(${getImage(data.LeftCircle).images.fallback
              .src});
            background-repeat: no-repeat;
            background-position-x: 50%;
            background-position-y: 150%;
            background-size: contain;
            background-size: 7.75rem;
            z-index: 1;
          }
          @media (max-width: 576px) {
            margin: 0 1rem;
          }
        `
        return (
          <StyledContainer>
            <StyledBox>
              <Row css={`position: relative;z-index: 2;}`}>
                <Col sm={12} md={8} lg={6}>
                  <Row
                    css={`
                      position: relative;
                    `}
                  >
                    <Col sm={12}>
                      <Text
                        textAlign="left"
                        fontSize={[
                          "1.5rem",
                          "1.8rem",
                          "2.2rem",
                          "2.5rem",
                          "2.6rem",
                        ]}
                        color="#FFFFFF"
                        fontWeight={"bold"}
                        lineHeight={["32px", "38px", "40px", "42px", "50px"]}
                      >
                        Refiere a tus amigos y gana dinero.
                      </Text>
                    </Col>
                    <Col sm={12}>
                      <Text
                        textAlign="left"
                        mt={[1, 1, 2, 3]}
                        fontSize={[
                          "0.6rem",
                          "0.7rem",
                          "0.8rem",
                          "0.9rem",
                          "1rem",
                        ]}
                        color="#FFFFFF"
                        fontWeight={"400"}
                        lineHeight={["16px", "20px"]}
                      >
                        Invita a tus amigos para que también sean parte de
                        Factcil y recibe dinero para que lo uses en lo que
                        quieras.
                      </Text>
                    </Col>
                    <Col sm={12}>
                      <OverlineArrowButton
                        text={"Referir ahora"}
                        to={`${process.env.GATSBY_AIO_APP_URL}/profile/referral`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  sm={12}
                  md={4}
                  lg={6}
                  className="d-flex justify-content-end align-items-end"
                >
                  <StyledTermsBox>
                    <Link to="/tos" className="link text-decoration-none">
                      <HoverStyled
                        textAlign="left"
                        fontSize={[
                          "0.6rem",
                          "0.7rem",
                          "0.8rem",
                          "0.9rem",
                          "1rem",
                        ]}
                        color="primary"
                        fontWeight={"700"}
                        lineHeight={["16px", "20px"]}
                      >
                        *Aplican términos y condiciones.
                      </HoverStyled>
                    </Link>
                  </StyledTermsBox>
                </Col>
              </Row>
            </StyledBox>
          </StyledContainer>
        )
      }}
    />
  )
}
