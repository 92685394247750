import React from "react"
import styled from "styled-components"
import { Button } from "../../components/Core"

const HoverStyled = styled(Button)`
  &:hover span {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.textHover};
        transition-timing-function: ease-in-out !important;
        -webkit-transition-timing-function: ease-in-out !important;
        transition-duration: 300ms;
      }
    }
  }
`

export const OverlineArrowButton = ({ text, to }) => {
  return (
    <a href={to} className="link text-decoration-none">
      <HoverStyled
        mt={3}
        variant="factcilSolidSecondary"
        className="link-button border-white"
      >
        {text} {"\u00A0"}
        <span
          css={`
            align-self: end;
          `}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.129 5.22859L5.106 1.09077L6.1665 -2.69546e-07L12 6L6.1665 12L5.106 10.9092L9.129 6.77141L-2.28549e-07 6.77141L-2.95988e-07 5.22859L9.129 5.22859Z"
              fill="#002432"
            />
          </svg>
        </span>
      </HoverStyled>
    </a>
  )
}
