import React from "react"
import { Container } from "react-bootstrap"
import { ReferralBanners } from "../components/Banners/ReferralBanners"
import MainPost from "../components/Blog/MainPost"
import PopularPosts from "../components/Blog/Popular"
import { Text, Title } from "../components/Core"
import { HighlightedText } from "../sections/pricing/PricingSection"

const Blog = ({ pageContext }) => {
  const posts = pageContext.posts
  return (
    <Container
      className="d-flex flex-column justify-content-center align-items-center "
      style={{ marginTop: 150 }}
    >
      <Title
        variant="banner"
        style={{ paddingLeft: 430, paddingRight: 430, textAlign: "center" }}
      >
        Aprende a llevar mejor tu vida independiente
        <HighlightedText>. </HighlightedText>
      </Title>
      <Text
        mb={[3, 2, 3, 4]}
        fontSize={["1rem", "1rem", "1rem", "1.18rem", "1.25rem"]}
        color="primary"
        className={"text-center p-0 pb-3"}
        opacity={0.9}
        fontWeight={"400"}
        lineHeight={["20px", "24px"]}
        css="max-width: 70%; text-align:center !important; display: inline-block;"
      >
        Encuentra tips para prestar tus servicios como todo un experto.
      </Text>
    </Container>
  )
}

export default Blog
