import { Link } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import { Container, Row, Stack } from "react-bootstrap"
import { Text } from "../Core"

const MainPost = ({ post }) => {
  const author = post.author

  return (
    <Container>
      <Row className="align-items-center">
        <div className="col col-lg-6">
          <img
            src={post.featuredImage.node.sourceUrl}
            alt={post.featuredImage.node.altText}
            style={{ maxWidth: "30rem", minHeight: "10rem" }}
          />
        </div>
        <div className="col col-lg-6">
          <Stack direction="vertical" gap={2}>
            <Text
              color="#717584"
              fontSize={[
                "0.75rem",
                "0.75rem",
                "0.75rem",
                "0.75rem",
                "0.875rem",
              ]}
            >
              {post.date}
            </Text>
            <Link to={`/blog${post.uri}`} css="text-decoration: none">
              <Text
                color="primary"
                fontSize={["1.2rem", "1.2rem", "1.2rem", "1.3rem", "1.5rem"]}
                fontWeight={"700"}
                css={`
                  &:hover {
                    text-decoration: underline !important;
                    color: ${({ theme, color }) =>
                      theme.colors.footerHighlight} !important;
                  }
                `}
                style={{
                  width: "70%",
                  cursor: "pointer",
                }}
              >
                {post.title}
              </Text>
            </Link>
            <Text
              color="primary"
              fontSize={["1.2rem", "1.2rem", "1.2rem", "1.3rem", "1.5rem"]}
              fontWeight={"400"}
              style={{ width: "70%" }}
              css={`
                color: ${({ theme }) => theme.colors.primary} !important;
                > p {
                  color: ${({ theme }) => theme.colors.primary} !important;
                }
                > h2 {
                  font-size: 1.5rem !important;
                  color: ${({ theme }) => theme.colors.primary} !important;
                }
              `}
            >
              {parse(post.excerpt)}
            </Text>
            <Row className="justify-content-start align-items-center">
              <Stack direction="horizontal" gap={3}>
                <img
                  src={author.node.avatar.url}
                  style={{ borderRadius: "18.75rem", maxWidth: "2.5rem" }}
                />
                <Stack direction="vertical" gap={0.5}>
                  <Text
                    color="#717584"
                    fontSize={[
                      "0.75rem",
                      "0.75rem",
                      "0.75rem",
                      "0.7rem",
                      "0.75rem",
                    ]}
                    style={{ height: "1rem" }}
                  >
                    Redactado por:
                  </Text>
                  <Text
                    color="primary"
                    fontSize={[
                      "0.75rem",
                      "0.75rem",
                      "0.75rem",
                      "0.75rem",
                      "0.875rem",
                    ]}
                  >
                    {author.node.name}
                  </Text>
                </Stack>
              </Stack>
            </Row>
          </Stack>
        </div>
      </Row>
    </Container>
  )
}

export default MainPost
