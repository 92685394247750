import { Link } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import { Container, Row, Stack } from "react-bootstrap"
import { HighlightedText } from "../../sections/pricing/PricingSection"
import { Text } from "../Core"

const PopularPosts = ({
  posts,
  removeImg,
  sectionTitle,
  col = 4,
  mt = "4rem",
  width = "70%",
}) => {
  return (
    <Container style={{ marginTop: mt }}>
      <Text
        mb={[3, 2, 3, 4]}
        fontSize={["1rem", "1rem", "1rem", "1.18rem", "1.25rem"]}
        color="primary"
        className={"text-center p-0 pb-3"}
        opacity={0.9}
        fontWeight={"700"}
        lineHeight={["20px", "24px"]}
        css={`
          max-width: ${width};
          text-align: left !important;
          display: inline-block;
        `}
      >
        {sectionTitle}
        <HighlightedText>. </HighlightedText>
      </Text>
      <Row>
        {posts.map(post => {
          const title =
            parse(post.node.title)[0]?.props?.children || post.node.title
          return (
            <div className={`col col-lg-${col}`}>
              <Stack direction="vertical" gap={1}>
                {!removeImg && (
                  <img
                    src={post.node.featuredImage.node.sourceUrl}
                    alt={post.node.featuredImage.node.altText}
                    style={{
                      maxWidth: "30rem",
                      maxHeight: "10rem",
                      objectFit: "fill",
                    }}
                    width={300}
                    height={320}
                  />
                )}
                <Text
                  color="#717584"
                  fontSize={[
                    "0.75rem",
                    "0.75rem",
                    "0.75rem",
                    "0.75rem",
                    "0.875rem",
                  ]}
                >
                  {post.node.date}
                </Text>
                <Link to={`/blog${post.node.uri}`} css="text-decoration: none">
                  <Text
                    fontSize={["1rem", "1rem", "1rem", "1.18rem", "1.25rem"]}
                    color="primary"
                    className={"text-center p-0 pb-3"}
                    opacity={0.9}
                    lineHeight={["20px", "24px"]}
                    css={`
                      max-width: ${width};
                      text-align: left !important;
                      display: inline-block;
                      &:hover {
                        text-decoration: underline !important;
                        color: ${({ theme, color }) =>
                          theme.colors.footerHighlight} !important;
                      }
                      height: 4rem;
                      font-weight: 700 !important;
                      strong {
                        font-weight: 700;
                      }
                    `}
                  >
                    {title.length > 60
                      ? parse(title.substring(0, 60) + "...")
                      : parse(post.node.title)}
                  </Text>
                </Link>
                <Text
                  color="primary"
                  fontSize={["1rem", "1rem", "1rem", "1.2rem", "1.3rem"]}
                  style={{ width: width }}
                  css={`
                    font-weight: 200 !important;
                    p {
                      font-weight: 200 !important;
                    }
                  `}
                >
                  <p
                    css={`
                      color: ${({ theme }) => theme.colors.primary} !important;
                      > p {
                        color: ${({ theme }) =>
                          theme.colors.primary} !important;
                      }
                      > h2 {
                        font-size: 1.5rem !important;
                        color: ${({ theme }) =>
                          theme.colors.primary} !important;
                      }
                    `}
                  >
                    {parse(post.node.excerpt)}
                  </p>
                </Text>
                <Row className="justify-content-start align-items-center">
                  <Stack direction="horizontal" gap={3}>
                    <img
                      src={post.node.author.node.avatar.url}
                      style={{
                        borderRadius: "18.75rem",
                        maxWidth: "2.5rem",
                      }}
                    />
                    <Stack direction="vertical" gap={0.5}>
                      <Text
                        color="#717584"
                        fontSize={[
                          "0.75rem",
                          "0.75rem",
                          "0.75rem",
                          "0.7rem",
                          "0.75rem",
                        ]}
                        style={{ height: "1rem" }}
                      >
                        Redactado por:
                      </Text>
                      <Text
                        color="primary"
                        fontSize={[
                          "0.75rem",
                          "0.75rem",
                          "0.75rem",
                          "0.75rem",
                          "0.875rem",
                        ]}
                      >
                        {post.node.author.node.name}
                      </Text>
                    </Stack>
                  </Stack>
                </Row>
              </Stack>
            </div>
          )
        })}
      </Row>
    </Container>
  )
}

export default PopularPosts
